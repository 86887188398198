<script>
    import { getContext, onMount } from 'svelte';
    import PointLight from '../../Icons/PointLight.svelte';
    import { Button } from '$lib/components/ui/button';

    const settingsWalletContext = getContext('settingsWalletContext');
    const {
        paypal_client_id,
        paypal_secret,
        stripe_secret_key,
        coinbase_api_key,
        nowpayments_api_key,
        ccbillCredentialsProvided,
        paystack_secret_key,
        stripe_public_key,
        stripe_oxxo_provider_enabled,
        allow_manual_payments,
        offline_payments_iban,
        offline_payments_swift,
        offline_payments_bank_name,
        offline_payments_owner,
        offline_payments_account_number,
        offline_payments_routing_number,
        pointsPackages,
    } = settingsWalletContext;

    let selectedPackage = null;

    function toggleSelection(pkg) {
        selectedPackage = selectedPackage === pkg ? null : pkg;
    }

    onMount(() => {
        document.addEventListener('DOMContentLoaded', function () {
            var boxes = document.querySelectorAll('.box');
            var continueButton = document.querySelector('.deposit-continue-btn');

            boxes.forEach(function (box) {
                box.addEventListener('click', function () {
                    boxes.forEach(function (b) {
                        b.classList.remove(
                            '!border-bmn-400',
                            'shadow-[0px_0px_50px_10px_rgba(45,255,196,0.15)]',
                            'shadow-bmn-500/30',
                            'dark:!shadow-bmn-400/50',
                            'dark:!shadow-[0px_0px_50px_10px_rgba(45,255,196,0.15)]',
                            'deposit-package-select'
                        );
                    });
                    this.classList.toggle(
                        '!border-bmn-400',
                        'shadow-[0px_0px_50px_10px_rgba(45,255,196,0.15)]',
                        'shadow-bmn-500/30',
                        'dark:!shadow-bmn-400/50',
                        'dark:!shadow-[0px_0px_50px_10px_rgba(45,255,196,0.15)]',
                        'deposit-package-select'
                    );

                    if (document.querySelectorAll('.box.deposit-package-select').length > 0) {
                        continueButton.disabled = false;
                        DepositSettings.amount = parseFloat(this.dataset.amt);
                    } else {
                        continueButton.disabled = true;
                    }
                });
            });

            // Checkout proceed button event listener
            document.querySelector('.deposit-continue-btn').addEventListener('click', function () {
                DepositSettings.initPayment();
            });

            document.querySelectorAll('.custom-control').forEach(function (control) {
                control.addEventListener('change', function () {
                    document
                        .querySelectorAll('.error-message, .invalid-files-error, .payment-error')
                        .forEach(function (el) {
                            el.style.display = 'none';
                        });
                    DepositSettings.triggerManualPaymentDetails();
                });
            });

            DepositSettings.initUploader();
        });

        /**
         * Deposit class
         */
        var DepositSettings = {
            stripe: null,
            paymentProvider: null,
            amount: null,
            myDropzone: null,
            uploadedFiles: [],
            manualPaymentDescription: null,

            /**
             * Instantiates new payment session
             */
            initPayment: function () {
                if (!DepositSettings.depositAmountValidation()) {
                    return false;
                }

                let processor = DepositSettings.getSelectedPaymentMethod();
                if (processor !== false) {
                    document.querySelector('.paymentProcessorError').style.display = 'none';
                    document.querySelectorAll('.error-message').forEach(function (el) {
                        el.style.display = 'none';
                    });
                    if (processor === 'manual') {
                        let paymentValidation = DepositSettings.manualPaymentValidation();
                        if (!paymentValidation) {
                            return false;
                        }
                    }
                    DepositSettings.updateDepositForm();
                    document.querySelector('.payment-button').click();
                } else {
                    document.querySelector('.payment-error').classList.remove('d-none');
                }
            },

            /**
             * Returns currently selected payment method
             */
            getSelectedPaymentMethod: function () {
                const val = document.querySelector('input[name="payment-radio-option"]:checked');
                if (val) {
                    switch (val.value) {
                        case 'payment-stripe':
                            DepositSettings.provider = 'stripe';
                            break;
                        case 'payment-paypal':
                            DepositSettings.provider = 'paypal';
                            break;
                        case 'payment-coinbase':
                            DepositSettings.provider = 'coinbase';
                            break;
                        case 'payment-manual':
                            DepositSettings.provider = 'manual';
                            break;
                        case 'payment-nowpayments':
                            DepositSettings.provider = 'nowpayments';
                            break;
                        case 'payment-ccbill':
                            DepositSettings.provider = 'ccbill';
                            break;
                        case 'payment-paystack':
                            DepositSettings.provider = 'paystack';
                            break;
                        case 'payment-oxxo':
                            DepositSettings.provider = 'oxxo';
                            break;
                    }
                    return DepositSettings.provider;
                }
                return false;
            },

            /**
             * Show payment details on deposit form
             */
            triggerManualPaymentDetails: function () {
                let paymentMethod = this.getSelectedPaymentMethod();
                let manualDetails = document.querySelector('.manual-details');
                if (paymentMethod === 'manual') {
                    manualDetails.classList.remove('d-none');
                } else {
                    manualDetails.classList.add('d-none');
                }
            },

            /**
             * Updates deposit form with predefined values
             */
            updateDepositForm: function () {
                document.getElementById('payment-type').value = 'deposit';
                document.getElementById('provider').value = DepositSettings.provider;
                document.getElementById('wallet-deposit-amount').value = DepositSettings.amount;
                document.getElementById('manual-payment-files').value = DepositSettings.uploadedFiles.join(',');
                document.getElementById('manual-payment-description').value =
                    document.getElementById('manualPaymentDescription').value;
            },

            /**
             * Validates deposit amount field
             * @returns {boolean}
             */
            depositAmountValidation: function () {
                const depositAmount = DepositSettings.amount;
                if (
                    !depositAmount ||
                    (depositAmount &&
                        (parseFloat(depositAmount) < parseFloat(app.depositMinAmount) ||
                            parseFloat(depositAmount) > parseFloat(app.depositMaxAmount)))
                ) {
                    document.getElementById('deposit-amount').classList.add('is-invalid');
                    return false;
                } else {
                    document.getElementById('deposit-amount').classList.remove('is-invalid');
                    document.getElementById('wallet-deposit-amount').value = depositAmount;
                    return true;
                }
            },

            /**
             * Instantiates the media uploader
             */
            initUploader: function () {
                try {
                    let selector = '.dropzone';
                    DepositSettings.myDropzone = new window.Dropzone(selector, {
                        url: app.baseUrl + '/attachment/upload/payment-request',
                        previewTemplate: document.querySelector('#tpl').innerHTML,
                        paramName: 'file', // The name that will be used to transfer the file
                        headers: {
                            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                        },
                        maxFilesize: mediaSettings.max_file_upload_size, // MB
                        addRemoveLinks: true,
                        dictRemoveFile: 'x',
                        acceptedFiles: mediaSettings.manual_payments_file_extensions,
                        dictDefaultMessage: trans('Drop files here to upload'),
                        autoDiscover: false,
                        previewsContainer: '.dropzone-previews',
                        autoProcessQueue: true,
                        parallelUploads: 1,
                    });

                    DepositSettings.myDropzone.on('addedfile', function (file) {
                        FileUpload.updatePreviewElement(file, true);
                    });
                    DepositSettings.myDropzone.on('success', function (file, response) {
                        DepositSettings.uploadedFiles.push(response.attachmentID);
                        file.upload.attachmentId = response.attachmentID;
                        DepositSettings.manualPaymentValidation();
                    });
                    DepositSettings.myDropzone.on('removedfile', function (file) {
                        DepositSettings.removeAsset(file.upload.attachmentId);
                        DepositSettings.uploadedFiles = DepositSettings.uploadedFiles.filter(function (uploadedFile) {
                            return uploadedFile !== file.upload.attachmentId;
                        });
                    });
                    DepositSettings.myDropzone.on('error', function (file, errorMessage) {
                        if (typeof errorMessage.errors !== 'undefined') {
                            // launchToast('danger',trans('Error'),errorMessage.errors.file)
                            Object.keys(errorMessage.errors).forEach(function (field) {
                                launchToast('danger', trans('Error'), errorMessage.errors[field]);
                            });
                        } else {
                            if (typeof errorMessage.message !== 'undefined') {
                                launchToast('danger', trans('Error'), errorMessage.message);
                            } else {
                                launchToast('danger', trans('Error'), errorMessage);
                            }
                        }
                        DepositSettings.myDropzone.removeFile(file);
                    });
                } catch (e) {
                    console.error(e);
                }
            },

            /**
             * Removes the uploaded asset
             * @param attachmentId
             */
            removeAsset: function (attachmentId) {
                fetch(app.baseUrl + '/attachment/remove', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                    body: JSON.stringify({ attachmentId: attachmentId }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            launchToast('success', trans('Success'), trans('Attachment removed.'));
                        } else {
                            launchToast('danger', trans('Error'), trans('Failed to remove the attachment.'));
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        launchToast('danger', trans('Error'), trans('Failed to remove the attachment.'));
                    });
            },

            /**
             * Validates manual payment files
             * @returns {boolean}
             */
            manualPaymentValidation: function () {
                const uploadedFilesCount = DepositSettings.uploadedFiles.length;
                if (uploadedFilesCount < 1) {
                    document.querySelector('.invalid-files').classList.remove('d-none');
                    return false;
                } else {
                    document.querySelector('.invalid-files').classList.add('d-none');
                    return true;
                }
            },
        };
    });
</script>

<div>
    <div class="payment-method">
        {#if paypal_client_id && paypal_secret}
            <div class="custom-control custom-radio mb-1">
                <input
                    type="radio"
                    id="customRadio1"
                    name="payment-radio-option"
                    class="custom-control-input"
                    value="payment-paypal"
                />
                <label class="custom-control-label" for="customRadio1">Paypal</label>
            </div>
        {/if}
        {#if stripe_secret_key && stripe_public_key}
            <div class="custom-control custom-radio mb-1">
                <input
                    type="radio"
                    id="customRadio2"
                    name="payment-radio-option"
                    class="custom-control-input"
                    value="payment-stripe"
                />
                <label
                    class="custom-control-label stepTooltip"
                    for="customRadio2"
                    title=""
                    data-original-title="You need to login first">Stripe</label
                >
            </div>
        {/if}
        {#if coinbase_api_key}
            <div class="custom-control custom-radio mb-1">
                <input
                    type="radio"
                    id="customRadio3"
                    name="payment-radio-option"
                    class="custom-control-input"
                    value="payment-coinbase"
                />
                <label class="custom-control-label stepTooltip" for="customRadio3" title="">Coinbase</label>
            </div>
        {/if}
        {#if nowpayments_api_key}
            <div class="custom-control custom-radio mb-1">
                <input
                    type="radio"
                    id="customRadio5"
                    name="payment-radio-option"
                    class="custom-control-input"
                    value="payment-nowpayments"
                />
                <label class="custom-control-label stepTooltip" for="customRadio5" title="">NowPayments Crypto</label>
            </div>
        {/if}
        {#if ccbillCredentialsProvided}
            <div class="custom-control custom-radio mb-1">
                <input
                    type="radio"
                    id="customRadio6"
                    name="payment-radio-option"
                    class="custom-control-input"
                    value="payment-ccbill"
                />
                <label class="custom-control-label stepTooltip" for="customRadio6" title="">CCBill</label>
            </div>
        {/if}
        {#if paystack_secret_key}
            <div class="custom-control custom-radio mb-1">
                <input
                    type="radio"
                    id="customRadio7"
                    name="payment-radio-option"
                    class="custom-control-input"
                    value="payment-paystack"
                />
                <label class="custom-control-label stepTooltip" for="customRadio7" title="">Paystack</label>
            </div>
        {/if}
        {#if stripe_secret_key && stripe_public_key && stripe_oxxo_provider_enabled}
            <div class="custom-control custom-radio mb-1">
                <input
                    type="radio"
                    id="customRadio8"
                    name="payment-radio-option"
                    class="custom-control-input"
                    value="payment-oxxo"
                />
                <label class="custom-control-label stepTooltip" for="customRadio8" title="">Oxxo</label>
            </div>
        {/if}
        {#if allow_manual_payments}
            <div class="custom-control custom-radio mb-1">
                <input
                    type="radio"
                    id="customRadio4"
                    name="payment-radio-option"
                    class="custom-control-input"
                    value="payment-manual"
                />
                <label class="custom-control-label stepTooltip" for="customRadio4" title="">Bank transfer</label>
            </div>
            <div class="manual-details d-none">
                <h5 class="mb-3 mt-4">Add payment details</h5>

                <div class="alert alert-primary font-weight-bold text-white" role="alert">
                    <p class="mb-0">
                        Once confirmed, your credit will be available and you will be notified via email.
                    </p>
                    <ul class="mb-2 mt-2">
                        <li>
                            IBAN:
                            {offline_payments_iban}
                        </li>
                        <li>
                            BIC/SWIFT:
                            {offline_payments_swift}
                        </li>
                        <li>
                            Bank name:
                            {offline_payments_bank_name}
                        </li>
                        <li>
                            Account owner:
                            {offline_payments_owner}
                        </li>
                        <li>
                            Account number:
                            {offline_payments_account_number}
                        </li>
                        <li>
                            Routing number:
                            {offline_payments_routing_number}
                        </li>
                    </ul>
                </div>

                <div>
                    <label for="manualPaymentDescription" title="">Description</label>
                    <textarea class="form-control" id="manualPaymentDescription" rows="1"></textarea>
                </div>
                <p class="mb-1 mt-2">
                    Please attach clear photos with one the following: check, money order or bank transfer.
                </p>
                <div
                    class="dropzone-previews dropzone manual-payment-uploader w-100 ppl-0 rounded border pb-1 pr-0 pt-1"
                ></div>
                <div class="text-danger invalid-files d-none">Please upload at least one file</div>
            </div>
        {/if}
    </div>
    <div class="payment-error error text-danger d-none mt-3">Please select your payment method</div>
    <div class="mt-3 grid grid-cols-2 gap-4">
        {#each pointsPackages as pointsPackage}
            <button
                class="box flex cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-solid p-6 text-center transition duration-500 hover:bg-gray-200 dark:bg-opacity-10 {selectedPackage ===
                pointsPackage
                    ? 'border-indigo-500 bg-indigo-50 shadow-2xl shadow-bmn-500/30 dark:bg-indigo-900 dark:bg-opacity-20'
                    : 'border-transparent bg-gray-100'}"
                data-amt="{pointsPackage['dollar_amount']}"
                on:click="{() => toggleSelection(pointsPackage)}"
            >
                <div class="flex items-center justify-center">
                    <span class="text-2xl font-bold text-black dark:!text-gray-300">
                        {pointsPackage['points']}
                    </span>&nbsp;
                    <svelte:component this="{PointLight}" />
                </div>
                <span class="text-xl font-light text-black dark:!text-gray-300">${pointsPackage['dollar_amount']}</span>
            </button>
        {/each}
    </div>
    <!-- <button class="btn text-xl !bg-bmn-500 hover:!bg-bmn-600 text-white btn-block rounded-lg mr-0 mt-4 deposit-continue-btn cursor-pointer disabled:cursor-not-allowed" type="submit" disabled >Deposit</button> -->
    <Button
        class="w-100 btn btn-block deposit-continue-btn mr-0 mt-4 cursor-pointer rounded-lg !bg-bmn-500 text-white hover:!bg-bmn-600 disabled:cursor-not-allowed"
        type="submit"
        disabled>Deposit</Button
    >
</div>
